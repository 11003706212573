import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d05aab4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-title text-left text-2xl font-bold" }
const _hoisted_2 = { class: "flex space-x-small" }
const _hoisted_3 = { class: "flex space-x-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": true
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString($setup.isViewBrandOwnerDetail ? "Brand Owner Information" : "Tier 1 Information"), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: $setup.initialValues,
        "label-position": "top",
        "label-width": "150px",
        class: "custom-form"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_form_item, {
              class: _normalizeClass($setup.initialValues.ProvinceName ? 'half-width' : 'full-width'),
              label: "Country"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.initialValues.CountryName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.initialValues.CountryName) = $event)),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["class"]),
            ($setup.initialValues.ProvinceName)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  class: "half-width",
                  label: "Province"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $setup.initialValues.ProvinceName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.initialValues.ProvinceName) = $event)),
                      autocomplete: "off",
                      disabled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_el_form_item, { label: "Company Registration Number" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.initialValues.CompanyRegistrationNo,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.initialValues.CompanyRegistrationNo) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Company Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.initialValues.CompanyName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.initialValues.CompanyName) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Email" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.initialValues.Email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.initialValues.Email) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Address" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.initialValues.Address,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.initialValues.Address) = $event)),
                autocomplete: "off",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_form_item, {
              class: "width-one-third",
              label: "Phone Code"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.initialValues.PhoneCode,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.initialValues.PhoneCode) = $event)),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              class: "width-two-third",
              label: "Contact Number"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.initialValues.PhoneNumber,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.initialValues.PhoneNumber) = $event)),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}